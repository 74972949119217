import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import VueGtag from "vue-gtag"

Vue.config.productionTip = false
Vue.prototype.$category =  {items: ""}
Vue.use(Toast)

Vue.use(VueGtag, {
  config: { id: "G-C9SR6L210J" }
});

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
