<template>
    <div>
      <v-row>
        <v-col cols="12" lg="3">
          
          <v-sheet
            rounded
            outlined
            class="pt-3 px-6 pt-4 pb-2 mb-5"
          >
            <h4 class="overline filter-header">Search FAQ's</h4>
            <v-text-field
              v-model="searchFaq"
              label="Search FAQ's"
              :readonly=isSearch
              class=""
              outlined
              clear-icon="mdi-close"
              clearable
              append-icon="mdi-magnify"
              :disabled=isTag
              @click:append="searchFaqsTest"
              @click:clear="clearSearch"
              @keydown.enter="searchFaqsTest"
            ></v-text-field>
          </v-sheet>

          
          <v-sheet
            rounded
            outlined
            class="pt-3 px-6 pt-4 pb-6 mb-5"
          >
            <h4 class="overline filter-header">FAQ Tags</h4>
            
            <div class=""></div>
            <div v-for="faqTag in faqTags" :key="faqTag.tag_ID">
              <v-checkbox
                v-model="fTag"
                class="mb-n6"
                :value="faqTag.tag_ID"
                :label="faqTag.tag"
                @change="parseDocs($event, faqTag.tag_ID, faqTag.tag)"
              ></v-checkbox>
            </div>
            
            <!-- <v-checkbox
              v-model="faqTags"
              v-for="faqTag in faqTags"
              :key="faqTag.tag_ID"
              class="mb-n6"
              :value="faqTag.tag_ID"
              :label="faqTag.tag"
              @change="parseDocs($event, faqTag.tag_ID, faqTag.tag)"
            ></v-checkbox> -->

          </v-sheet>  
        </v-col>
        <v-col cols="12" lg="9">
          <v-row justify="center">
            <v-col cols="10">
             
            <div id="faq-top" class=""></div>
            <h1 class="mb-6 font-weight-thin text-uppercase">Frequently Asked Questions</h1>
              
            
            <v-sheet
              v-if="!isTag && !isSearch"
              rounded
              outlined
              class="pt-2 px-2 pt-2 pb-2 mb-5"
            >
              <v-row class="mt-6 mb-6">
                <v-col v-for="faqList in faqHierarchy" :key="faqList.faq_cat_ID">
                  <h4 class="mb-1">
                    <v-btn class="text-capitalize" color="primary" text @click="$vuetify.goTo('#' + faqList.faq_category_slug)">{{ faqList.faq_category }}</v-btn>
                  </h4>
                  <ul class="faq-links">
                    <li v-for="faqSub in faqList.subcats" :key="faqSub.faq_cat_ID">
                      <v-btn class="text-capitalize font-weight-regular text-decoration-underline" color="primary" text @click="$vuetify.goTo('#' + faqList.faq_category_slug + '-' + faqSub.faq_category_slug)">{{ faqSub.faq_category }}</v-btn>
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </v-sheet>
            <!-- begin: Chips -->
            <div class="mt-6 mb-6">
              <v-chip
                v-for="filters in filterChips"
                :key="filters.id"
                class="mt-2 mr-2 mb-2"
                close
                @click:close="parseDocs('chip', filters.id, filters.name)"
              >
                <span v-html="filters.name"></span>
              </v-chip>
              <v-btn
                v-if="jumpToSearch"
                class="text-decoration-underline text-lowercase font-weight-regular"
                text
                rounded
                small
                color="black"
                @click="$vuetify.goTo(firstResultElement)"
              >
                jump to first result
              </v-btn>
              <v-btn
                v-if="filterChips.length > 0"
                text
                rounded
                small
                color="info"
                @click="clearFilters"
              >
                Clear All
              </v-btn>
            </div>
            <!-- end: Chips -->

            <!-- begin: FAQ LIST -->

            <div v-if="isTag">
              <v-expansion-panels  focusable multiple class="my-5 py-4">
                <v-expansion-panel  class="" v-for="faqByTag in faqByTags" :key="faqByTag.faq_ID">
                  <v-expansion-panel-header>
                    <div class="text-subtitle-1 font-weight-medium" v-html="faqByTag.faq_title"></div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="">
                    <br>
                    <div v-html="faqByTag.faq_content"></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <div v-else>
              <v-row v-for="faqList in faqData" :key="faqList.faq_cat_ID" :id="faqList.faq_category_slug">
                <v-col>
                  <v-row>
                    <v-col cols="11">
                      <h1 class="my-4 font-weight-light">{{ faqList.faq_category }}</h1>
                    </v-col>
                    <v-col cols="1"  class="text-right">
                      <v-btn
                      class="my-4"
                        icon
                        small
                        color="#b65c39"
                        @click="$vuetify.goTo('#faq-top')"
                      >
                        <v-icon>mdi-chevron-up-circle-outline</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <!-- TOP LEVEL -->
                  
                  <div v-for="faqSub in faqList.faqCatData" :key="faqSub.faq_cat_ID" :id="faqList.faq_category_slug + '-' + faqSub.faq_category_slug ">
                    
                    <v-row>
                      <v-col cols="11">
                        <h3 class="font-weight-regular">{{ faqSub.faq_category }}</h3>
                      </v-col>
                      <v-col cols="1" class="text-right">
                        <v-btn
                          icon
                          color="grey"
                          small
                          @click="$vuetify.goTo('#' + faqList.faq_category_slug)"
                        >
                          <v-icon>mdi-chevron-up-circle-outline</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                   
                    <v-expansion-panels  focusable multiple class="my-5 py-4">
                      <v-expansion-panel   
                        v-for="faqItem in faqSub.faqData" 
                        :key="faqItem.ID"
                      >
                        <v-expansion-panel-header>
                          <div :id="'expansion-title-' + faqItem.faq_ID" class="faq-collapse-title text-subtitle-1 font-weight-medium" v-html="faqItem.faq_title"></div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="">
                          <br>
                          <div v-html="faqItem.faq_content"></div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                 
                
                  </div>  
                
                </v-col>
              </v-row>

            </div>  
            <!-- end: FAQ LIST -->

          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
  import jwt_decode from "jwt-decode"

  export default { 
    name: 'FrequentlyAskedQuestions', 
    data: () => ({
      overlay: false,
      //faqHierarchy: [{"faq_cat_ID":16,"faq_category":"Instrument","faq_category_slug":"instrument","subcats":[{"faq_cat_ID":17,"faq_category":"Specifications","faq_category_slug":"specifications","subcats":[]},{"faq_cat_ID":18,"faq_category":"Troubleshooting","faq_category_slug":"troubleshooting","subcats":[]}]},{"faq_cat_ID":19,"faq_category":"Software","faq_category_slug":"software","subcats":[{"faq_cat_ID":20,"faq_category":"Pipelines","faq_category_slug":"pipelines","subcats":[]},{"faq_cat_ID":21,"faq_category":"Image Processing","faq_category_slug":"image-processing","subcats":[]},{"faq_cat_ID":22,"faq_category":"Extraction Matrix","faq_category_slug":"extraction-matrix","subcats":[]},{"faq_cat_ID":23,"faq_category":"Viewing Images","faq_category_slug":"viewing-images","subcats":[]}]},{"faq_cat_ID":24,"faq_category":"Reagents","faq_category_slug":"reagents","subcats":[{"faq_cat_ID":25,"faq_category":"Fixation and Antigen Retrieval","faq_category_slug":"fixation-and-antigen-retrieval","subcats":[]},{"faq_cat_ID":26,"faq_category":"Autofluorescence","faq_category_slug":"autofluorescence","subcats":[]}]}],
      faqHierarchy: [],
      faqData: null,
      faqDataReset: null,
      searchFaq: '',
      searchResultsFound: '',
      firstResultElement: null,
      isSearch: false,
      jumpToSearch: false,
      faqTags: [],
      expansionCount: 0,
      expansions: [],
      panel: {
        id: []
      },
      filterChips: [],
      tagCategoriesParsed: [],
      fTag: [],
      isTag: false,
      faqByTags: [],
    }),
    beforeMount(){
      //get faqHierarchy
      //get faqs
      this.getFaqHierarchy()
      this.getFaqs()
      this.getFaqTags()
    },
    methods: {
      test($event){
        console.log($event)
      },
      searchFaqsTest(){

        this.overlay = true

        const questions = document.querySelectorAll(".faq-collapse-title")
        const resetObj = this.faqData
        this.faqDataReset = resetObj

        let counter = 0
        let ttlFound = 0
        let firstResultElement = null

        // const searchPhrase = this.searchFaq
        // var expression = `/${searchPhrase}/i`;
        

        for (let i = 0; i < resetObj.length; i++) {
          let faqCatData = resetObj[i].faqCatData

          
          
          for (let ii = 0; ii < faqCatData.length; ii++) {

            let faqData = faqCatData[ii].faqData

            

            for (let iii = 0; iii < faqData.length; iii++) {
              // actual FAQ
              // let tempFaqObj = {}
              
              //SEARCH PHRASE
              // let s = /test/i
              
              //QUESTION
              let newTitle = faqData[iii].faq_title;
              let titleText = faqData[iii].faq_title;
              let titleIndex = titleText.search(new RegExp(this.searchFaq, "i"));
              let triggerClick = false

              if( titleIndex > -1){
                newTitle = titleText.substr(0, titleIndex) + '<span style="background: #FCF3CF !important;">' + titleText.substr(titleIndex)
                newTitle += '</span>' 

                this.faqData[i].faqCatData[ii].faqData[iii].faq_title = newTitle
                triggerClick = true

                ttlFound++

                if( ttlFound === 1){
                  firstResultElement = questions[counter]
                }

              }

              //ANSWER
              let newAnwser = faqData[iii].faq_content;
              let answerText = faqData[iii].faq_content;
              let answerIndex = answerText.search(new RegExp(this.searchFaq, "i"));
              
              if( answerIndex > -1){
                newAnwser = answerText.substr(0, answerIndex) + '<span style="background: #FCF3CF !important;">' + answerText.substr(answerIndex)
                newAnwser += '</span>' 

                this.faqData[i].faqCatData[ii].faqData[iii].faq_content = newAnwser
                triggerClick = true

                ttlFound++

                if( ttlFound === 1){
                  firstResultElement = questions[counter]
                }

              }


              if(triggerClick){
                questions[counter].click()
              }
              // console.log(titleIndex)

              counter++
            }

            

          }
          
        }

        //  console.log(`Total Found: ${ttlFound}`)
        //  console.log(`Scroll to first: ${firstResultElement}`)

        this.searchResultsFound = `(${ttlFound}) results found for <strong>${this.searchFaq}</strong>`
        
        setTimeout(()=>{
          if(ttlFound > 0){
            this.jumpToSearch = true
            this.firstResultElement = firstResultElement
          }else{
            this.faqData = []
          }

          this.parseDocs('search', 0, this.searchResultsFound)

          this.isSearch = true
          this.overlay = false

        },500)

      },

      clearSearch() {
        this.jumpToSearch = false
        this.searchFaq = ''
        this.searchResultsFound = ''
        this.filterChips = []
        this.isSearch = false
        this.firstResultElement = null
        this.getFaqs()
      },

      searchFaqs(){
        this.expansions = [0]

        this.searchResultsFound = ''

        if(this.faqDataReset){
          this.faqData = []
          this.faqData = this.faqDataReset
        }

        const resetObj = this.faqData
        this.faqDataReset = resetObj
        let newData = []
        let newTempObj = {}
        let subArr = []
        var toSearch = this.searchFaq;
        
        let ttlFound = 0


        for (let i = 0; i < resetObj.length; i++) {
          
          let faqCatData = resetObj[i].faqCatData

          for (let ii = 0; ii < faqCatData.length; ii++) {

            let faqData = faqCatData[ii].faqData
            let tempFaqData = []
            for (let iii = 0; iii < faqData.length; iii++) {
              let tempFaqObj = {}

              //SEARCH QUESTION
              let newTitle = faqData[iii].faq_title;
              let titleText = faqData[iii].faq_title;
              let titleIndex = titleText.indexOf(toSearch);
              

              if( titleIndex > 0){
                ttlFound++
                newTitle = titleText.substr(0, titleIndex) + '<span style="background: #FCF3CF !important;">' + titleText.substr(titleIndex)
                newTitle += '</span>' 
              }

              //SEARCH ANSWER
              let newAns = faqData[iii].faq_content;
              let ansText = faqData[iii].faq_content;
              let ansIndex = ansText.indexOf(toSearch);
              

              if( ansIndex > 0){
                ttlFound++
                newAns = ansText.substr(0, ansIndex) + '<span style="background: #FCF3CF !important;">' + ansText.substr(ansIndex)
                newAns += '</span>' 
              }
             
              tempFaqObj = {
                ID: faqData[iii].ID,
                faq_cat_ID: faqData[iii].faq_cat_ID,
                faq_ID: faqData[iii].faq_ID,
                display_order: faqData[iii].display_order,
                faq_title: newTitle, //faqData[iii].faq_title,
                faq_content: newAns,
                active: faqData[iii].active
              }

              tempFaqData.push(tempFaqObj)
            }

            let tempSubs = {
              faq_cat_ID: faqCatData[ii].faq_cat_ID,
              faq_category: faqCatData[ii].faq_category,
              faq_category_slug: faqCatData[ii].faq_category_slug,
              faqData: tempFaqData
            }

            

            

             subArr.push(tempSubs)

          }

          newTempObj = {
            faq_cat_ID: resetObj[i].faq_cat_ID,
            faq_category: resetObj[i].faq_category,
            faq_category_slug: resetObj[i].faq_category_slug,
            faqCatData: subArr
          }

          newData.push(newTempObj)
        }
        
        //console.log(newData)
        
        this.faqData = newData
        this.searchResultsFound = `(${ttlFound}) results found for <strong>${this.searchFaq}</strong>`
        
        this.parseDocs('search', 0, this.searchResultsFound)
        /*
        {
    "faq_cat_ID": 16,
    "faq_category": "Instrument",
    "faq_category_slug": "instrument",
    "faqCatData": [
      {
        "faq_cat_ID": 17,
        "faq_category": "Specifications",
        "faq_category_slug": "specifications",
        "faqData":
        */

      },
      async getFaqHierarchy () {

        const userObj = JSON.parse(localStorage.getItem('rccpcst'))

        var endpoint = '/portal/settings'
        const url = process.env.VUE_APP_API_BASE_URL + endpoint

        let postData = {
          action: "faq-navigation"
        }

        const request = new Request(url, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData),
          mode: 'cors',
        })  
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {
          
          if(jsonResponse.success) {
            const navObj = JSON.parse(jsonResponse.data[0].category_schema_json)
            this.faqHierarchy = navObj

          }
        })
      },
      async getFaqs() {
        this.overlay = true 
        this.faqData = []

        const userObj = JSON.parse(localStorage.getItem('rccpcst'))
        const userInfo = jwt_decode(userObj.jwt)
        if(userInfo.ap == 0){
          setTimeout(()=>{
            this.adminApproved = false
            this.overlay = false
            return
          },750)
        }
        
        var endpoint = `/portal/content-faq`
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
        
        const request = new Request(url, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          mode: 'cors',
        })
        
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {
          

          setTimeout(()=>{
            this.faqData = jsonResponse
            this.overlay = false
          },750)

          //console.log(jsonResponse)
        })
        // const faqObj = this.faqHierarchy
        // let faqDisplayObj = {}

        // for (let i = 0; i < faqObj.length; i++) {
          
        //   let loopId = faqObj[i].faq_cat_ID
        //   let loopCat = faqObj[i].faq_category
        //   let loopSlug = faqObj[i].faq_category_slug
        //   let loopSubs = faqObj[i].subcats

        //   if(id === loopId) {
        //     loopCat = newCatName
        //   }

        //   loopSubCatArr = navObj[i].subcats
        //   tempSubsArr  = []

        //   if(loopSubCatArr.length > 0){
        //     for (let iSubs = 0; iSubs < loopSubCatArr.length; iSubs++) {
        //       subsId = loopSubCatArr[iSubs].faq_cat_ID
        //       subsCat = loopSubCatArr[iSubs].faq_category
        //       subsSlug = loopSubCatArr[iSubs].faq_category_slug

        //       if(id === subsId) {
        //         subsCat = newCatName
        //       }

        //       loopSubCatObj = {
        //         faq_cat_ID: subsId,
        //         faq_category: subsCat,
        //         faq_category_slug: subsSlug,
        //         subcats: []
        //       }

        //       tempSubsArr.push(loopSubCatObj)

        //     }
        //   }

        //   tempObj = {
        //     faq_cat_ID: loopId,
        //     faq_category: loopCat,
        //     faq_category_slug: loopSlug,
        //     subcats: tempSubsArr  
        //   }

        //   newNavArr.push(tempObj)

        // }

      },
      async getFaqsByTags() {
        
        // this.faqData = []
        this.faqByTags = []
        this.overlay = true
        this.isTag = true

        const userObj = JSON.parse(localStorage.getItem('rccpcst'))
        const userInfo = jwt_decode(userObj.jwt)
        if(userInfo.ap == 0){
          setTimeout(()=>{
            this.adminApproved = false
            this.overlay = false
            return
          },750)
        }
        
        var endpoint = `/portal/content-faq/filterTag/${this.fTag}`
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
        
        const request = new Request(url, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          mode: 'cors',
        })
        
        const faqTagResults = await fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {
          return jsonResponse
          //console.log(jsonResponse)
          //this.faqData = jsonResponse
        })

        // console.log(faqTagResults.length)

        setTimeout(()=>{
          
          this.faqByTags = faqTagResults
          this.overlay = false
        },750)
        
      },

      async getFaqTags() {
        this.faqTags = []

        const userObj = JSON.parse(localStorage.getItem('rccpcst'))
        const userInfo = jwt_decode(userObj.jwt)
        if(userInfo.ap == 0){
          setTimeout(()=>{
            this.adminApproved = false
            this.overlay = false
            return
          },750)
        }
        

        var endpoint = `/portal/content-faq/getFaqTags`
        const url = process.env.VUE_APP_API_BASE_URL + endpoint
        
        const request = new Request(url, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + userObj.jwt,
            'Content-Type': 'application/json'
          },
          mode: 'cors',
        })
        
        fetch(request)
        .then(response => {
          return response.json();
        })
        .then((jsonResponse) => {
          this.faqTags = jsonResponse
           // console.log(jsonResponse)
        })
      },
      
      parseDocs(checked, id, name) {

        let filterChipsCurrent = this.filterChips
        let filterChipsTemp = []

        if(checked === 'search'){
          
          this.filterChips = []
          
          let chipObj2 = {
            id: id,
            name: name
          }

          let newChips = []
          newChips.push(chipObj2)

          this.filterChips = newChips

          return

        }else if( checked === 'chip' ){
          let cbArr = []
          for (let i = 0; i < filterChipsCurrent.length; i++) {
            
            //if search
            if( filterChipsCurrent[i].id === 0){
              this.clearFilters()
              return
            }

            if( filterChipsCurrent[i].id != id){
              let chipObj2 = {
                id: filterChipsCurrent[i].id,
                name: filterChipsCurrent[i].name
              }
              filterChipsTemp.push(chipObj2)
              cbArr.push(filterChipsCurrent[i].id)
            } 
            this.fTag = cbArr
          }

          this.filterChips = filterChipsTemp
        }else{

          if( checked.includes(id) ){
            let chipObj2 = {
              id: id,
              name: name
            }

            filterChipsCurrent.push(chipObj2)
          }else{

            for (let i = 0; i < filterChipsCurrent.length; i++) {
              
              if( filterChipsCurrent[i].id != id){
                let chipObj2 = {
                  id: filterChipsCurrent[i].id,
                  name: filterChipsCurrent[i].name
                }
                filterChipsTemp.push(chipObj2)
              } 
            }

            this.filterChips = filterChipsTemp

          }

        }

        if( this.filterChips.length == 0){
          
          // console.log("resetfaq")

          this.overlay = true
          //this.isTag = false
          this.faqByTags = []
          this.searchFaq = ''

          setTimeout(()=>{
            this.isTag = false
            this.overlay = false
          },750)

        }else{
          this.getFaqsByTags()
        }

      },
      clearFilters(){
        //this.overlay = true
        this.filterChips = []
        this.faqByTags = []
        this.fTag =[]
        this.searchFaq = ''
        this.jumpToSearch = false
        this.isSearch = false
        this.firstResultElement = null
        this.getFaqs()
        
        setTimeout(()=>{
          this.isTag = false
          //this.overlay = false
        },750)
      }
    }
  } 
  </script>
<style lang="scss" scoped>
ul.faq-links{
  list-style: none;
}
ul.faq-links li{
  margin-bottom: 2px;
}
.html-content img{
  width: 300px !important;
}
</style>